import React from "react";
import { Routes, Route } from "react-router-dom";
import { PrivateRoutes, LoginRoutes } from "../context/authContext";
import Dashboard from "../components/dashboard/Dashboard";
import Layout from "../components/layout/Layout";
import Profile from "../components/profile/Profile";
import DownloadHistory from "../components/downloadHistory/DownloadHistory";

//=========================================To do module=========================================//
import CreateNewTask from "../components/todo/CreateNewTask";
import ShowTask from "../components/todo/ShowTask";
import ShowAssignedTask from "../components/todo/ShowAssignedTask";
import ShowRecurrenceTask from "../components/todo/ShowRecurrenceTask";

//=========================================Notification module=========================================//
import SendNotification from "../components/notification/SendNotification";
import NotificationHistory from "../components/notification/NotificationHistory";

//=========================================Gallery module=========================================//
import AddImage from "../components/gallery/AddImage";
import ShowImages from "../components/gallery/ShowImages";

//=========================================Sale Data module=========================================//
import ChangeSaleData from "../components/saleData/changeSaleDate/index";
import UpdateSaleFile from "../components/saleData/UpdateSaleFile";
import AddProductSale from "../components/saleData/AddProductSale";
import SaleActivation from "../components/saleData/SaleActivation";

//=========================================Authentication module=========================================//
import Login from "../components/login/Login";
import Signup from "../components/signup";

//=========================================Coupons module=========================================//
import CouponStats from "../components/coupons/CouponStats";
import AddCoupon from "../components/coupons/AddCoupon";
import ShowCoupon from "../components/coupons/ShowCoupon";
import ShowUserCoupon from "../components/coupons/ShowUserCoupon";
import AssignSite from "../components/coupons/AssignSite";
import ShowRecentlyUsedCoupon from "../components/coupons/ShowRecentlyUsedCoupon";

//=========================================Profile Operation module=========================================//
import ViewAdditionalRoles from "../components/operations/ViewAdditionalRoles";
import UserRoles from "../components/operations/UserRoles";
import ViewModules from "../components/operations/ViewModules";
import RoleModules from "../components/operations/RoleModules";
import AllUser from "../components/operations/AllUser";
import Approve from "../components/operations/Approve";
import CreateUser from "../components/operations/CreateUser";

//=========================================Website Data module=========================================//
import ShowWishlist from "../components/websiteData/wishlist/ShowWishlist";
import ShowRecentPriceDrop from "../components/websiteData/recent_price_drop/ShowRecentPriceDrop";
import LiveBanners from "../components/websiteData/banner/LiveBanners";
import UploadBanner from "../components/websiteData/banner/UploadBanner";
import LiveOffers from "../components/websiteData/banner/LiveOffers";
import ShowSales from "../components/websiteData/sales/ShowSales";
import AddSales from "../components/websiteData/sales/AddSales";
import ShowDeals from "../components/websiteData/deals/ShowDeals";
import AddDeal from "../components/websiteData/deals/AddDeal";
import ShowWebsiteImage from "../components/websiteData/image/ShowWebsiteImage";
import AddWebsiteImage from "../components/websiteData/image/AddWebsiteImage";
import HeaderFooter from "../components/websiteData/header-footer/HeaderFooter";
import ShortLinkUrl from "../components/websiteData/shortLinkUrl/index";
import ShowShortLink from "../components/websiteData/shortLinkUrl/ShowShortLink";

//=========================================Stores module=========================================//
import ShowStores from "../components/stores/ShowStores";
import AddStore from "../components/stores/AddStore";
import AddTrendingStore from "../components/stores/AddTrendingStore";
import ShowTrendingStores from "../components/stores/ShowTrendingStores";
import AddStoreCategory from "../components/stores/store_category/AddStoreCategory";
import ManageCategory from "../components/stores/store_category/ManageCategory";
import AssignStoreCategory from "../components/stores/store_category/AssignStoreCategory";
import AddStoreDescription from "../components/stores/AddStoreDescription";
import ManageStoreDescription from "../components/stores/ManageStoreDescription";
import AddStoreSchema from "../components/stores/AddStoreSchema";
import ShowStoreSchema from "../components/stores/ShowStoreSchema";

//=========================================Live Deals module=========================================//
import LiveDealsProcessed from "../components/liveDeals/LiveDealsProcessed";
import LiveDeals from "../components/liveDeals/LiveDeals";

//=========================================Job Posting module=========================================//
import AddJobPosting from "../components/jobPostings/AddJobPosting";
import ManageJobPostings from "../components/jobPostings/ManageJobPostings";

//=========================================Product Details module=========================================//
import AddProductPid from "../components/productDetails/AddProductPid";
import ManageProductPid from "../components/productDetails/ManageProductPid";
import DeeplinkSupportStores from "../components/stores/DeeplinkSupport";
import ShowAacStores from "../components/stores/AacStores/ShowAacStores";
import AddAacStore from "../components/stores/AacStores/AddAacStores";
import TelegramMessageSender from "../components/TelegramMessageSender";

//=========================================User Details module=========================================//
import UserDeatils from "../components/userDetails/UserDeatils";
import ShowDealsApp from "../components/dealsApp/ShowDealsApp";
import AddDealsApp from "../components/dealsApp/AddDealsApp";
import SacrpingUrls from "../components/scraping_urls";
import AddScrapUrls from "../components/scraping_urls/AddScrapUrls";
import AppBannerImages from "../components/gallery/appBannerImage";

function MainRoutes() {
  return (
    <Routes>
      {/* Authenticate Routes */}
      <Route element={<LoginRoutes />}>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
      </Route>

      {/* Protected Routes */}
      <Route
        element={
          <Layout>
            <PrivateRoutes />
          </Layout>
        }
      >
        <Route path="/" element={<Dashboard />} />
        <Route path="/profile" element={<Profile />} />

        {/* Operations */}
        <Route
          path="/operation/additional-roles"
          element={<ViewAdditionalRoles />}
        />
        <Route path="/operation/user-roles" element={<UserRoles />} />
        <Route path="/operation/modules" element={<ViewModules />} />
        <Route path="/operation/role-modules" element={<RoleModules />} />
        <Route path="/admin/all-users" element={<AllUser />} />
        <Route path="/admin/approve" element={<Approve />} />
        <Route path="/admin/create-user" element={<CreateUser />} />

        {/* To do routes */}
        <Route path="/todo/create-new-task" element={<CreateNewTask />} />
        <Route path="/todo/show-task" element={<ShowTask />} />
        <Route path="/todo/show-assigned-task" element={<ShowAssignedTask />} />
        <Route
          path="/todo/show-recurrence-task"
          element={<ShowRecurrenceTask />}
        />

        {/* Download History */}
        <Route path="/download-history" element={<DownloadHistory />} />

        {/* Notification */}
        <Route path="/notification/send" element={<SendNotification />} />
        <Route path="/notification/history" element={<NotificationHistory />} />

        {/* Gallery */}
        <Route path="/gallery/add-image" element={<AddImage />} />
        <Route path="/gallery/show-image" element={<ShowImages />} />
        <Route path="/gallery/app-banner-image" element={<AppBannerImages />} />

        {/* Coupons*/}
        <Route path="/coupons/coupon-stats" element={<CouponStats />} />
        <Route path="/coupons/add-coupon" element={<AddCoupon />} />
        <Route path="/coupons/show-coupon" element={<ShowCoupon />} />
        <Route path="/coupons/show-user-coupon" element={<ShowUserCoupon />} />
        <Route path="/coupons/assign-site" element={<AssignSite />} />
        <Route
          path="/coupons/recently-used-coupon"
          element={<ShowRecentlyUsedCoupon />}
        />

        {/* Sale Data */}
        <Route path="/saledata/change-date" element={<ChangeSaleData />} />
        <Route path="/saledata/update-file" element={<UpdateSaleFile />} />
        <Route path="/saledata/add-product-sale" element={<AddProductSale />} />
        <Route path="/saledata/activation" element={<SaleActivation />} />

        {/* Live Deals */}
        <Route path="/livedeals" element={<LiveDeals />} />
        <Route path="/livedeals-processed" element={<LiveDealsProcessed />} />

        {/* Website Data */}
        <Route path="/websitedata/show-wishlist" element={<ShowWishlist />} />
        <Route
          path="/websitedata/show-recent-price-drop"
          element={<ShowRecentPriceDrop />}
        />
        <Route path="/websitedata/live-banner" element={<LiveBanners />} />
        <Route path="/websitedata/upload-banner" element={<UploadBanner />} />
        <Route path="/websitedata/live-offers" element={<LiveOffers />} />
        <Route path="/websitedata/show-sales" element={<ShowSales />} />
        <Route path="/websitedata/add-sales" element={<AddSales />} />
        <Route path="/websitedata/show-deals" element={<ShowDeals />} />
        <Route path="/websitedata/add-deal" element={<AddDeal />} />
        <Route
          path="/websitedata/show-website-image"
          element={<ShowWebsiteImage />}
        />
        <Route
          path="/websitedata/add-website-image"
          element={<AddWebsiteImage />}
        />
        <Route path="/websitedata/header-footer" element={<HeaderFooter />} />
        <Route path="/websitedata/short-link" element={<ShortLinkUrl />} />
        <Route
          path="/websitedata/show-short-link"
          element={<ShowShortLink />}
        />

        {/* Stores */}
        <Route path="/stores/manage-stores" element={<ShowStores />} />
        <Route path="/stores/add-store" element={<AddStore />} />
        <Route
          path="/stores/manage-trendingstore"
          element={<ShowTrendingStores />}
        />
        <Route
          path="/stores/add-trendingstore"
          element={<AddTrendingStore />}
        />
        <Route
          path="/stores/manage-store-category"
          element={<ManageCategory />}
        />
        <Route
          path="/stores/add-store-category"
          element={<AddStoreCategory />}
        />
        <Route
          path="/stores/assign-store-category"
          element={<AssignStoreCategory />}
        />
        <Route
          path="/stores/add-store-description"
          element={<AddStoreDescription />}
        />
        <Route
          path="/stores/manage-stores-description"
          element={<ManageStoreDescription />}
        />
        <Route path="/stores/add-store-schema" element={<AddStoreSchema />} />
        <Route path="/stores/show-store-schema" element={<ShowStoreSchema />} />
        <Route
          path="/stores/deeplink-support"
          element={<DeeplinkSupportStores />}
        />
        <Route path="/stores/aac-stores" element={<ShowAacStores />} />
        <Route path="/stores/add-aac-store" element={<AddAacStore />} />

        {/* scrap_urls data  */}
        <Route
          path="/scrap_urls/add-showscrapeddata"
          element={<SacrpingUrls />}
        />
        <Route path="/scrap_urls/add-scrapeddata" element={<AddScrapUrls />} />

        {/* Job Postings */}
        <Route path="/job-posting/add-post" element={<AddJobPosting />} />
        <Route path="/job-posting/manage" element={<ManageJobPostings />} />

        {/* product */}
        <Route path="/products/add-product" element={<AddProductPid />} />
        <Route
          path="/products/manage-products"
          element={<ManageProductPid />}
        />
        <Route path="/messageconvertor" element={<TelegramMessageSender />} />

        {/* {user details} */}
        <Route path="/user_details" element={<UserDeatils />} />

        {/* Deals_app */}
        <Route path="/show-deals-app" element={<ShowDealsApp />} />
        <Route path="/add-deals-app" element={<AddDealsApp />} />

        {/* Wildcard Route */}
        <Route path="*" element={<h4>Error 404: Not found.</h4>} />
      </Route>

      {/* Unauthenticated Route */}

      {/* <Route path='/login' element={<LoginRoutes><Login /></LoginRoutes>} /> */}
    </Routes>
  );
}
export default MainRoutes;
