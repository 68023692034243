import React, { useEffect } from "react";
import Datatable from "../../Datatable/Datatable";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getAppBannerImageData,
  updateStatusAppbannerImages,
} from "../../../redux/features/gallerySlice";
import { NavLink } from "react-router-dom";
import { Popconfirm } from "antd";

function AppBannerImages() {
  // id	type_id	title	alt_text	img_url	width	height	redirect_url	priority	active	inserted_at
  const columns = [
    {
      name: "id",
      label: "Action",
      options: {
        customBodyRender: (id, tableMeta) => {
          const dealData = {
            id: id,
            type_id: tableMeta.rowData[1],
            image_type: tableMeta.rowData[2],
            title: tableMeta.rowData[3],
            alt_text: tableMeta.rowData[3],
            img_url: tableMeta.rowData[5],
            redirect_url: tableMeta.rowData[6],
            width: tableMeta.rowData[7],
            height: tableMeta.rowData[8],
            priority: tableMeta.rowData[9],
            active: tableMeta.rowData[10],
            inserted_at: tableMeta.rowData[11],
          };
          // console.log(dealData, "dealData");
          return (
            <NavLink to="/gallery/add-image" state={{ dealData }}>
              <Button size="small" color="success" variant="outlined">
                <strong>Edit</strong>
              </Button>
            </NavLink>
          );
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "type_id",
      label: "Type ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "image_type",
      label: "Image Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "title",
      label: "Title",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "alt_text",
      label: "Alt Text",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "img_url",
      label: "Image URL",
      options: {
        customBodyRender: (link) => {
          return (
            <img
              src={link}
              alt="img"
              style={{ width: "200px", height: "200px" }}
            />
          );
        },
        filter: true,
        sort: true,
      },
    },

    {
      name: "redirect_url",
      label: "Cashback Upto",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "width",
      label: "Image width",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "height",
      label: "height",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "priority",
      label: "priority",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "active",
      label: "Status",
      options: {
        customBodyRender: (active, tableMeta) => {
          const activationData = {
            id: tableMeta.rowData[0],
            status: tableMeta.rowData[10] === 0 ? "activate" : "deactivate",
          };
          if (active)
            return (
              <Popconfirm
                placement="topRight"
                title="Deactivate Store"
                description="Do you really want to deactivate the store?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => handleDealActivation(activationData)}
              >
                <Button color="error" size="small" variant="outlined">
                  <b>Deactivate</b>
                </Button>
              </Popconfirm>
            );
          else
            return (
              <Popconfirm
                placement="topRight"
                title="Activate Store"
                description="Do you really want to activate the store?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => handleDealActivation(activationData)}
              >
                <Button color="success" size="small" variant="outlined">
                  <b>Activate</b>
                </Button>
              </Popconfirm>
            );
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "inserted_at",
      label: "Insert Date",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const dispatch = useDispatch();
  const handleDealActivation = (data) => {
    console.log(data, "data");
    dispatch(updateStatusAppbannerImages(data));
    dispatch(getAppBannerImageData());
  };

  const { appBannerImgData } = useSelector((state) => state.gallery);

  useEffect(() => {
    dispatch(getAppBannerImageData());
  }, []);

  console.log(appBannerImgData, "appBannerImgData");
  return (
    <div>
      {" "}
      <Datatable
        name="App Banner Images"
        columns={columns}
        data={appBannerImgData && appBannerImgData}
      />
    </div>
  );
}

export default AppBannerImages;
