import axios from "axios";
import AppConstants from "../../constants/AppConstants";

// const API = axios.create({ baseURl: AppConstants.baseURL});
const API = axios.create({ baseURL: AppConstants.baseURL });
// Gallery

export const getGalleryData = async (limit) =>
  await API.get(`/gallery/allimage?limit=${limit}`);

export const uploadImage = async (formData) =>
  await API.post(`/gallery/uploadimage`, formData);

export const deleteImage = async (formData) =>
  await API.post(`/gallery/deleteimage`, formData);

export const getImageType = async () => await API.get(`/gallery/imagetype`);

export const checkImageDimension = async () =>
  await API.get(`/gallery/checkDimension`);

export const updateImageData = async (formData) =>
  await API.post(`/gallery/updateImage`, formData);

export const getImageBySearch = async (formData) =>
  await API.post(`/gallery/searchImage`, formData);

export const sendTelegramMessageApi = async (formData) =>
  await API.post("/gallery/sendtelegrammsg", formData); //==========> sendtelegrammsg

export const getAppBannerImages = async () =>
  await API.get("/gallery/getappbannerimg");

export const updateStatusbannerImagesApi = async (formData) =>
  await API.post("/gallery/updatestatusappbanner", formData); //==========> updatestatusappbanner

export const updateAppBannersDataApi = async (formData) =>
  await API.post("/gallery/updateappbannerdata", formData); //==========> updateAppBannersData
